import * as React from "react";
import styled from "styled-components";
import { graphql, Link, navigate } from "gatsby";
import { WorkPageQuery } from "../../types/graphql-types";
import Layout from "../components/Layout";
import { theme } from "../components/theme";
import { ReactImageGalleryItem } from "react-image-gallery";
import { ImageGalleryView } from "../components/imageGalleryView";
import { Helmet } from "react-helmet";
import { Meta } from "../components/Meta";
import { URL_NAME } from "../config/constans";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterShareButton,
  TwitterIcon
} from "react-share";
import MediaQuery from "react-responsive";
import { useEffect, useState } from "react";
import MarkdownViewer from "../components/MarkdownViewer";

interface WorkProps {
  data: WorkPageQuery;
  pageContext: {
    contentful_id: string;
    next: {
      contentful_id: string;
      title: string;
    };
    previous: {
      contentful_id: string;
      title: string;
    };
  };
}

interface WindowsSizeProps {
  width: number;
  height: number;
  vw: number;
  vh: number;
}

const Work: React.FC<WorkProps> = ({ data, pageContext }) => {
  const [windowSize, setWindowSize] = useState<WindowsSizeProps>();

  useEffect(() => {
    const w = window;
    setWindowSize({
      width: w.innerWidth,
      height: w.innerHeight,
      vw: w.innerWidth * 0.01,
      vh: w.innerHeight * 0.01
    });
  }, []);

  const work = data.allContentfulWork.edges[0].node;

  let images: ReactImageGalleryItem[] = [];

  if (work) {
    work.thumbnails.forEach(item => {
      images.push({
        original: `${item.file.url}?w=680`,
        thumbnail: `${item.file.url}?w=100`
      });
    });
  }

  return (
    <Layout>
      <Meta />
      <Helmet>
        <meta
          property="og:url"
          content={`${URL_NAME}/work/${work.contentful_id}`}
        />
        <meta property="og:title" content={`mogFlowt - ${work.title}`} />
        <title>{`mogFlowt - ${work.title}`}</title>
      </Helmet>

      <Container>
        <Header>
          <h1>{work.title}</h1>
        </Header>

        <ThumbnailsContainer>
          <ImageGalleryView
            items={images}
            autoPlay={false}
            showThumbnails={true}
            showNav={true}
          />
        </ThumbnailsContainer>

        <Body>
          <MarkdownViewer children={work.body.body} />
        </Body>

        {/* SP */}
        <MediaQuery query={`(max-width: ${theme.ConstBreakPoint.MAX_SP})`}>
          <YoutubeContainer>
            <iframe
              title="Work"
              width={windowSize ? `${windowSize.width}px` : "327px"}
              height={
                windowSize ? `${(windowSize.width / 690) * 327}px` : "327px"
              }
              src={`https://www.youtube.com/embed/${work.movieRef.youtubeId}`}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </YoutubeContainer>
        </MediaQuery>

        {/* PC */}
        <MediaQuery query={`(min-width: ${theme.ConstBreakPoint.MIN_PC})`}>
          <YoutubeContainer>
            <iframe
              title="Work"
              width="690"
              height="327"
              src={`https://www.youtube.com/embed/${work.movieRef.youtubeId}`}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </YoutubeContainer>
        </MediaQuery>

        <PaginationContainer>
          {pageContext.next.contentful_id !== "" && (
            <PaginationContent>
              <div
                onClick={() =>
                  navigate(`/work/${pageContext.next.contentful_id}`)
                }
              >
                次のページ
              </div>
              <Link to={`/work/${pageContext.next.contentful_id}`}>
                <div>{pageContext.next.title || ""}</div>
              </Link>
            </PaginationContent>
          )}

          {pageContext.previous.contentful_id !== "" && (
            <PaginationContent>
              <div
                onClick={() =>
                  navigate(`/work/${pageContext.previous.contentful_id}`)
                }
              >
                前のページ
              </div>
              <Link to={`/work/${pageContext.previous.contentful_id}`}>
                <div>{pageContext.previous.title || ""}</div>
              </Link>
            </PaginationContent>
          )}
        </PaginationContainer>

        <SNSContainer>
          <FacebookShareButton url={`${URL_NAME}/work/${work.contentful_id}`}>
            <FacebookIcon size={50} round />
          </FacebookShareButton>

          <TwitterShareButton
            title={work.title}
            url={`${URL_NAME}/work/${work.contentful_id}`}
          >
            <TwitterIcon size={50} round />
          </TwitterShareButton>
        </SNSContainer>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query WorkPage($contentful_id: String!) {
    allContentfulWork(filter: { contentful_id: { eq: $contentful_id } }) {
      edges {
        node {
          contentful_id
          title
          body {
            body
          }
          thumbnails {
            contentful_id
            file {
              url
            }
          }
          movieRef {
            youtubeId
          }
        }
      }
    }
  }
`;

export default Work;

const Container = styled.div`
  margin: 0 auto;

  width: 100vw;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    padding: 40px 0 80px;
    width: ${theme.width.work};
  }
`;

const Header = styled.div`
  margin-bottom: 30px;

  color: ${theme.colors.grey60};

  & > h1 {
    padding: 0 ${theme.width.paddingSideSP};
    font-size: ${theme.fontSizes[0]};

    text-align: center;

    @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
      padding: 0;
      font-size: ${theme.fontSizes[2]};
    }
  }

  & > div:nth-child(2) {
    margin-bottom: 30px;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & > div:nth-child(1) {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      & > :nth-child(1) {
        margin-right: 30px;
      }
    }
  }
`;

const ThumbnailsContainer = styled.div`
  margin: 5px auto;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    width: ${theme.width.work};
  }
`;

const Body = styled.div`
  padding: 0 ${theme.width.paddingSideSP};
  width: 100vw;

  img {
    max-width: calc(100vw - 20px);
  }

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    padding: 0;
    width: ${theme.width.work};

    img {
      max-width: ${theme.width.work};
    }
  }
`;

const SNSContainer = styled.div`
  margin-top: 30px;
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;

  & > button {
    margin: 0 5px;
  }
`;

const YoutubeContainer = styled.div`
  padding: 0 ${theme.width.paddingSideSP};

  width: 100vw;

  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    width: calc(${theme.width.work} - 30px);
  }
`;

const PaginationContainer = styled.div`
  margin: 30px 0;
  width: 100vw;

  @media (min-width: ${theme.ConstBreakPoint.MIN_PC}) {
    width: calc(${theme.width.work} - 30px);
  }
`;

const PaginationContent = styled.div`
  margin: 10px 0;
  padding: 0 ${theme.width.paddingSideSP};

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & > :nth-child(1) {
    padding: 5px;
    margin-right: 15px;

    border: 1px solid ${theme.colors.grey40};
    border-radius: 5px;

    :hover {
      cursor: pointer;
    }
  }

  & > :nth-child(2) {
    text-decoration: none;
    color: ${theme.colors.grey40};
    font-weight: 600;

    :visited {
      color: ${theme.colors.grey40};
    }

    & > :nth-child(1) {
      max-width: 50vw;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`;
